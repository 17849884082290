/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {MatomoOptOut} from "../../src/components/Matomo/OptOut";
import {Seo} from "../../src/components/Seo";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    h3: "h3",
    p: "p",
    h4: "h4",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {Section} = _components;
  if (!Section) _missingMdxReference("Section", true);
  if (!Seo) _missingMdxReference("Seo", false);
  if (!Seo.Meta) _missingMdxReference("Seo.Meta", true);
  return React.createElement(React.Fragment, null, React.createElement(Seo.Meta, {
    noIndex: true
  }), "\n", React.createElement(Section, null, React.createElement(_components.h2, {
    id: "1-an-overview-of-data-protection"
  }, "1. An overview of data protection"), React.createElement(_components.h3, {
    id: "general"
  }, "General"), React.createElement(_components.p, null, "The following gives a simple overview of what happens to your personal information when you visit our website. Personal information is any data with which you could be personally identified. Detailed information on the subject of data protection can be found in our privacy policy found below."), React.createElement(_components.h3, {
    id: "data-collection-on-our-website"
  }, "Data collection on our website"), React.createElement(_components.h4, {
    id: "who-is-responsible-for-the-data-collection-on-this-website"
  }, "Who is responsible for the data collection on this website?"), React.createElement(_components.p, null, "The data collected on this website are processed by the website operator. The operator's contact details can be found in the website's required legal notice."), React.createElement(_components.h4, {
    id: "how-do-we-collect-your-data"
  }, "How do we collect your data?"), React.createElement(_components.p, null, "Some data are collected when you provide it to us. This could, for example, be data you enter on a contact form."), React.createElement(_components.p, null, "Other data are collected automatically by our IT systems when you visit the website. These data are primarily technical data such as the browser and operating system you are using or when you accessed the page. These data are collected automatically as soon as you enter our website."), React.createElement(_components.h4, {
    id: "what-do-we-use-your-data-for"
  }, "What do we use your data for?"), React.createElement(_components.p, null, "Part of the data is collected to ensure the proper functioning of the website. Other data can be used to analyze how visitors use the site."), React.createElement(_components.h4, {
    id: "what-rights-do-you-have-regarding-your-data"
  }, "What rights do you have regarding your data?"), React.createElement(_components.p, null, "You always have the right to request information about your stored data, its origin, its recipients, and the purpose of its collection at no charge. You also have the right to request that it be corrected, blocked, or deleted. You can contact us at any time using the address given in the legal notice if you have further questions about the issue of privacy and data protection. You may also, of course, file a complaint with the competent regulatory authorities."), React.createElement(_components.h3, {
    id: "analytics-and-third-party-tools"
  }, "Analytics and third-party tools"), React.createElement(_components.p, null, "When visiting our website, statistical analyses may be made of your surfing behavior. This happens primarily using cookies and analytics. The analysis of your surfing behavior is usually anonymous, i.e. we will not be able to identify you from this data. You can object to this analysis or prevent it by not using certain tools. Detailed information can be found in the following privacy policy."), React.createElement(_components.p, null, "You can object to this analysis. We will inform you below about how to exercise your options in this regard.")), "\n", React.createElement(Section, null, React.createElement(_components.h2, {
    id: "2-general-information-and-mandatory-information"
  }, "2. General information and mandatory information"), React.createElement(_components.h3, {
    id: "data-protection"
  }, "Data protection"), React.createElement(_components.p, null, "The operators of this website take the protection of your personal data very seriously. We treat your personal data as confidential and in accordance with the statutory data protection regulations and this privacy policy."), React.createElement(_components.p, null, "If you use this website, various pieces of personal data will be collected. Personal information is any data with which you could be personally identified. This privacy policy explains what information we collect and what we use it for. It also explains how and for what purpose this happens."), React.createElement(_components.p, null, "Please note that data transmitted via the internet (e.g. via email communication) may be subject to security breaches. Complete protection of your data from third-party access is not possible."), React.createElement(_components.h3, {
    id: "notice-concerning-the-party-responsible-for-this-website"
  }, "Notice concerning the party responsible for this website"), React.createElement(_components.p, null, "The party responsible for processing data on this website is:"), React.createElement(_components.p, null, "axes4 GmbH\nFrauentalweg 117\nCH-8045 Zürich\nEmail: privacy@axes4.com"), React.createElement(_components.p, null, "The responsible party is the natural or legal person who alone or jointly with others decides on the purposes and means of processing personal data (names, email addresses, etc.)."), React.createElement(_components.h3, {
    id: "revocation-of-your-consent-to-the-processing-of-your-data"
  }, "Revocation of your consent to the processing of your data"), React.createElement(_components.p, null, "Many data processing operations are only possible with your express consent. You may revoke your consent at any time with future effect. An informal email making this request is sufficient. The data processed before we receive your request may still be legally processed."), React.createElement(_components.h4, {
    id: "right-to-file-complaints-with-regulatory-authorities"
  }, "Right to file complaints with regulatory authorities"), React.createElement(_components.p, null, "If there has been a breach of data protection legislation, the person affected may file a complaint with the competent regulatory authorities."), React.createElement(_components.h4, {
    id: "right-to-data-portability"
  }, "Right to data portability"), React.createElement(_components.p, null, "You have the right to have data which we process based on your consent or in fulfillment of a contract automatically delivered to yourself or to a third party in a standard, machine-readable format. If you require the direct transfer of data to another responsible party, this will only be done to the extent technically feasible."), React.createElement(_components.h4, {
    id: "ssl-or-tls-encryption"
  }, "SSL or TLS encryption"), React.createElement(_components.p, null, "This site uses SSL or TLS encryption for security reasons and for the protection of the transmission of confidential content, such as the inquiries you send to us as the site operator. You can recognize an encrypted connection in your browser's address line when it changes from \"http://\" to \"https://\" and the lock icon is displayed in your browser's address bar."), React.createElement(_components.p, null, "If SSL or TLS encryption is activated, the data you transfer to us cannot be read by third parties."), React.createElement(_components.h4, {
    id: "encrypted-payments-on-this-website"
  }, "Encrypted payments on this website"), React.createElement(_components.p, null, "If you enter into a contract which requires you to send us your payment information (e.g. account number for direct debits), we will require this data to process your payment."), React.createElement(_components.p, null, "Payment transactions using common means of payment (Visa/MasterCard, direct debit) are only made via encrypted SSL or TLS connections. You can recognize an encrypted connection in your browser's address line when it changes from \"http://\" to \"https://\" and the lock icon in your browser line is visible."), React.createElement(_components.p, null, "In the case of encrypted communication, any payment details you submit to us cannot be read by third parties."), React.createElement(_components.h4, {
    id: "information-blocking-deletion"
  }, "Information, blocking, deletion"), React.createElement(_components.p, null, "As permitted by law, you have the right to be provided at any time with information free of charge about any of your personal data that is stored as well as its origin, the recipient and the purpose for which it has been processed. You also have the right to have this data corrected, blocked or deleted. You can contact us at any time using the address given in our legal notice if you have further questions on the topic of personal data."), React.createElement(_components.p, null, "Opposition to promotional emails"), React.createElement(_components.p, null, "We hereby expressly prohibit the use of contact data published in the context of website legal notice requirements with regard to sending promotional and informational materials not expressly requested. The website operator reserves the right to take specific legal action if unsolicited advertising material, such as email spam, is received.")), "\n", React.createElement(Section, null, React.createElement(_components.h2, {
    id: "3-data-collection-on-our-website"
  }, "3. Data collection on our website"), React.createElement(_components.h3, {
    id: "cookies"
  }, "Cookies"), React.createElement(_components.p, null, "Some of our web pages use cookies. Cookies do not harm your computer and do not contain any viruses. Cookies help make our website more user-friendly, efficient, and secure. Cookies are small text files that are stored on your computer and saved by your browser."), React.createElement(_components.p, null, "Most of the cookies we use are so-called \"session cookies.\" They are automatically deleted after your visit. Other cookies remain in your device's memory until you delete them. These cookies make it possible to recognize your browser when you next visit the site."), React.createElement(_components.p, null, "You can configure your browser to inform you about the use of cookies so that you can decide on a case-by-case basis whether to accept or reject a cookie. Alternatively, your browser can be configured to automatically accept cookies under certain conditions or to always reject them, or to automatically delete cookies when closing your browser. Disabling cookies may limit the functionality of this website."), React.createElement(_components.p, null, "Cookies which are necessary to allow electronic communications or to provide certain functions you wish to use (such as the shopping cart) are stored pursuant to Art. 6 paragraph 1, letter f of DSGVO. The website operator has a legitimate interest in the storage of cookies to ensure an optimized service provided free of technical errors. If other cookies (such as those used to analyze your surfing behavior) are also stored, they will be treated separately in this privacy policy."), React.createElement(_components.h3, {
    id: "server-log-files"
  }, "Server log files"), React.createElement(_components.p, null, "The website provider automatically collects and stores information that your browser automatically transmits to us in \"server log files\". These are:"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Browser type and browser version"), "\n", React.createElement(_components.li, null, "Operating system used"), "\n", React.createElement(_components.li, null, "Referrer URL"), "\n", React.createElement(_components.li, null, "Host name of the accessing computer"), "\n", React.createElement(_components.li, null, "Time of the server request"), "\n", React.createElement(_components.li, null, "IP address"), "\n", React.createElement(_components.li, null, "These data will not be combined with data from other sources."), "\n"), React.createElement(_components.p, null, "The basis for data processing is Art. 6 (1) (b) DSGVO, which allows the processing of data to fulfill a contract or for measures preliminary to a contract."), React.createElement(_components.h3, {
    id: "storage-of-the-ip-address"
  }, "Storage of the IP address"), React.createElement(_components.p, null, "Our comment function stores the IP addresses of those users who post comments. Since we do not check comments on our site before they go live, we need this information to be able to pursue action for illegal or slanderous content."), React.createElement(_components.h3, {
    id: "subscribing-to-the-comment-feed"
  }, "Subscribing to the comment feed"), React.createElement(_components.p, null, "As a user of this site, you can sign up to receive the comment feed after registering. Your email address will be checked with a confirmation email. You can unsubscribe from this function at any time by clicking the link in the emails. The data provided when you subscribed to the comments feed will then be deleted, but if you have submitted this data to us for other purposes or elsewhere (such as subscribing to a newsletter), it will be retained."), React.createElement(_components.h3, {
    id: "how-long-comments-are-stored"
  }, "How long comments are stored"), React.createElement(_components.p, null, "The comments and the associated data (e.g. IP address) are stored and remain on our website until the content commented upon has been completely deleted or the comments are required to be removed for legal reasons (slander, etc.)."), React.createElement(_components.h3, {
    id: "legal-basis"
  }, "Legal basis"), React.createElement(_components.p, null, "The comments are stored based on your consent per Art. 6 (1) (a) DSGVO. You may revoke your consent at any time with future effect. An informal email making this request is sufficient. The data processed before we receive your request may still be legally processed."), React.createElement(_components.h3, {
    id: "processing-of-data-customer-and-contract-data"
  }, "Processing of data (customer and contract data)"), React.createElement(_components.p, null, "We collect, process, and use personal data only insofar as it is necessary to establish, or modify legal relationships with us (master data). This is done based on Art. 6 (1) (b) DSGVO, which allows the processing of data to fulfill a contract or for measures preliminary to a contract. We collect, process and use your personal data when accessing our website (usage data) only to the extent required to enable you to access our service or to bill you for the same."), React.createElement(_components.p, null, "Collected customer data shall be deleted after completion of the order or termination of the business relationship. Legal retention periods remain unaffected."), React.createElement(_components.h3, {
    id: "data-transmitted-when-entering-into-a-contract-with-online-shops-retailers-and-mail-order"
  }, "Data transmitted when entering into a contract with online shops, retailers, and mail order"), React.createElement(_components.p, null, "We transmit personally identifiable data to third parties only to the extent required to fulfill the terms of your contract, for example, to companies entrusted to deliver goods to your location or banks entrusted to process your payments. Your data will not be transmitted for any other purpose unless you have given your express permission to do so. Your data will not be disclosed to third parties for advertising purposes without your express consent."), React.createElement(_components.p, null, "The basis for data processing is Art. 6 (1) (b) DSGVO, which allows the processing of data to fulfill a contract or for measures preliminary to a contract."), React.createElement(_components.h3, {
    id: "data-transferred-when-signing-up-for-services-and-digital-content"
  }, "Data transferred when signing up for services and digital content"), React.createElement(_components.p, null, "We transmit personally identifiable data to third parties only to the extent required to fulfill the terms of your contract with us, for example, to banks entrusted to process your payments."), React.createElement(_components.p, null, "Your data will not be transmitted for any other purpose unless you have given your express permission to do so. Your data will not be disclosed to third parties for advertising purposes without your express consent."), React.createElement(_components.p, null, "The basis for data processing is Art. 6 (1) (b) DSGVO, which allows the processing of data to fulfill a contract or for measures preliminary to a contract.")), "\n", React.createElement(Section, null, React.createElement(_components.h2, {
    id: "4-analytics-and-advertising"
  }, "4. Analytics and advertising"), React.createElement(_components.h3, {
    id: "ip-anonymization"
  }, "IP anonymization"), React.createElement(_components.p, null, "We have activated the IP anonymization feature on this website. Your IP address will be shortened by Google within the European Union or other parties to the Agreement on the European Economic Area prior to transmission to the United States. Only in exceptional cases is the full IP address sent to a Google server in the US and shortened there. Google will use this information on behalf of the operator of this website to evaluate your use of the website, to compile reports on website activity, and to provide other services regarding website activity and Internet usage for the website operator. The IP address transmitted by your browser as part of Google Analytics will not be merged with any other data held by Google."), React.createElement(_components.h3, {
    id: "browser-plugin"
  }, "Browser plugin"), React.createElement(_components.p, null, "You can prevent these cookies being stored by selecting the appropriate settings in your browser. However, we wish to point out that doing so may mean you will not be able to enjoy the full functionality of this website. You can also prevent the data generated by cookies about your use of the website (incl. your IP address) from being passed to Google, and the processing of these data by Google, by downloading and installing the browser plugin available at the following link: https://tools.google.com/dlpage/gaoptout?hl=en."), React.createElement(_components.h3, {
    id: "objecting-to-the-collection-of-data"
  }, "Objecting to the collection of data"), React.createElement(_components.p, null, "You can prevent the collection of your data by Google Analytics by clicking on the following link. An opt-out cookie will be set to prevent your data from being collected on future visits to this site: Disable Google Analytics."), React.createElement(_components.p, null, "For more information about how Google Analytics handles user data, see Google's privacy policy: https://support.google.com/analytics/answer/6004245?hl=en."), React.createElement(_components.h3, {
    id: "google-recaptcha"
  }, "Google reCAPTCHA"), React.createElement(_components.p, null, "We use \"Google reCAPTCHA\" (hereinafter \"reCAPTCHA\") on our websites. This service is provided by Google Inc., 1600 Amphitheater Parkway, Mountain View, CA 94043, USA (\"Google\")."), React.createElement(_components.p, null, "reCAPTCHA is used to check whether the data entered on our website (such as on a contact form) has been entered by a human or by an automated program. To do this, reCAPTCHA analyzes the behavior of the website visitor based on various characteristics. This analysis starts automatically as soon as the website visitor enters the website. For the analysis, reCAPTCHA evaluates various information (e.g. IP address, how long the visitor has been on the website, or mouse movements made by the user). The data collected during the analysis will be forwarded to Google."), React.createElement(_components.p, null, "The reCAPTCHA analyses take place completely in the background. Website visitors are not advised that such an analysis is taking place."), React.createElement(_components.p, null, "Data processing is based on Art. 6 (1) (f) DSGVO. The website operator has a legitimate interest in protecting its site from abusive automated crawling and spam."), React.createElement(_components.p, null, "For more information about Google reCAPTCHA and Google's privacy policy, please visit the following links: https://www.google.com/intl/de/policies/privacy/ and https://www.google.com/recaptcha/intro/android.html.")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
